import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Seo from './seo'
import Layout from '../layout/global'

import Gallery from '../components/Gallery'

import * as designSystem from '../components/DesignSystem'
import * as globalCls from './../layout/Global.module.css'

const pageTitle = "Gallery | Captain Bob Constructions"
const pageDescription = "See Captain Bob's previous work on past projects through our gallery."

const GalleryPage = () => {
	return (
		<main>
			<Seo title={pageTitle} description={pageDescription} pageUrl="/gallery/" />
			<Header pageName={pageTitle} pageDescription={pageDescription} pageUrl='/gallery/' />
			<Layout>
				{
					// Gallery Section
					<section className={`${globalCls.section} ${globalCls.padding} ${globalCls.grey__background}`}>
						<div className={`${globalCls.section__wrapper}`}>
							<designSystem.shortcodes.h1>Gallery</designSystem.shortcodes.h1>
							<Gallery display="full" />
						</div>
					</section>
				}
			</Layout>
		</main >
	)
}

export default GalleryPage

export const pageQuery = graphql`
  	query {
		allMdx {
			nodes {
			  body
			  frontmatter {
				page
				section
			  }
			}
		  }
	}
`